import React, { useEffect } from 'react';
import CardItem from './CardItem'
import './Cards.css'
import charlie from '../../images/headshots/charlie.webp'
import brad from '../../images/headshots/brad.webp'
import will from '../../images/headshots/will.webp'
import mark from '../../images/headshots/mark.jpg'
import trey from '../../images/headshots/Trey.webp'
import { useNavigate } from 'react-router-dom';

function Cards() {

  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='cards' id='cards'>
      <h1 className='card__header'>Meet Our Leadership</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className='cards__items'>
            <CardItem
              className='cards__self'
              src={will}
              text='Will Beazley, MAI'
              label='Principal'
              phone='214-214-2222'
              email='Will.Beazley@slatepts.com'
              onClick={() => {
                navigate('/will-beazley-bio');
                window.scrollTo(0, 0); // Scroll to top when navigating
              }}
              route='/will-beazley-bio'
            />
            <CardItem
              src={charlie}
              text='Charlie Young, CMI'
              label='Principal'
              phone='214-214-2222'
              email='Charlie.Young@slatepts.com'
              onClick={() => {
                navigate('/charlie-young-bio');
                window.scrollTo(0, 0);
              }}
              route='/charlie-young-bio'
            />
            <CardItem
              src={brad}
              text='Brad Matheidas'
              label='Principal'
              phone='214-214-2222'
              email='Brad.Matheidas@slatepts.com'
              onClick={() => {
                navigate('/brad-matheidas-bio');
                window.scrollTo(0, 0);
              }}
              route = '/brad-matheidas-bio'
            />
            <CardItem
              src={trey}
              text='Trey Beazley, MAI'
              label='Executive Director'
              phone='214-214-2222'
              email='Trey.Beazley@slatepts.com'
              onClick={() => {
                navigate('/trey-beazley-bio');
                window.scrollTo(0, 0);
              }}
              route='/trey-beazley-bio'
            />
            <CardItem
              src={mark}
              text='Mark Bedford, CMI.'
              label='Executive Director'
              phone='214-214-2222'
              email='Mark.Bedford@slatepts.com'
              // onClick={() => {
              //   navigate('/beverly-kirkpatrick-bio');
              //   window.scrollTo(0, 0);
              // }}
              // route='/beverly-kirkpatrick-bio'
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Cards;
