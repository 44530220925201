import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './components/pages/Home'
import Navbar from './components/Navbar';
import Bio from './components/routes/Bio';
import charlie from './images/headshots/charlie.webp'
import brad from './images/headshots/brad.webp'
import will from './images/headshots/will.webp'
import treyimg from './images/headshots/Trey.webp'
import assessmentImg from './images/services/assessment analysis 2.webp'
import dueImg from './images/services/due diligence FAV.webp'
import taxImg from './images/services/Tax Bills Transmittals.webp'
import appealsImg from './images/services/Appeals 2.webp'
import personalImg from './images/services/Personal Property.webp'
import budgetsImg from './images/services/budgets and insights 2.webp'
import ServiceDetails from './components/routes/ServiceDetails';
import NewFooter from './components/NewFooter';

function App() {
  const charlieYoung = {
    name: 'Charlie Young, CMI',
    role: 'Principal',
    bio: '\nCharlie Young has been in the property tax industry since 2017 and holds the CMI designation from the Institute of Professionals in Taxation.  Over his career, Mr. Young has focused on complex commercial property types including healthcare (hospitals, senior living, medical office, surgery centers, FSED’s) and hospitality assets. \n\r Charlie has reduced the property tax burden for clients across the country with his extensive knowledge of the intangible value embedded within complex assets. \n\nHe is a co-founder and principal of Slate Property Tax Solutions, a property tax consulting firm that helps commercial property owners effectively manage their property tax liability.  Before starting Slate, Charlie Young was a Senior Manager, Property Tax at Altus Group US. \n\nCharlie was born and raised in Southlake, Texas and attended Dallas Jesuit for high school.  He spends most of his free time with his Fiancée, Kelsey, his German Shepherd, Goose, or on the water with a fly rod.',
    education: 'Education: Texas A&M University - BBA Finance',
    photo: charlie,
    linkedin: 'https://www.linkedin.com/in/charlie-young-cmi-591387147/',
    email: 'Charlie.Young@slatepts.com'
  };
  const bradMateidas = {
    name: 'Brad Matheidas',
    role: 'Principal',
    bio: 'Brad Matheidas began his journey in real estate valuation and consulting while still in school at the University of Texas – Arlington, working on national, commercial property for Morey & Associates and Crosson & Dannis, both Dallas appraisal firms before going to work briefly at Pier 1 Imports in Fort Worth.\nBrad started his property tax career in 1996 with Rosemont & Associates Dallas office, moving to Complex Property Advisors Corp in Southlake, TX in 1998. He remained for 25 years finishing as a Senior Director and Lead for its Healthcare Real Property Division under the ownership of Altus Group.\nToday, Brad is a co-founder of Slate Property Tax Solutions based in Southlake, TX. Slate files and administers appeals while providing property tax guidance for both commercial and complex property on a national basis. Slate offers a full spectrum of property tax services for general commercial property (retail, office, industrial and multi-family) and for specialty or complex properties (healthcare, hospitality, stadiums, together with special purpose / single user type facilities that may have outside market considerations that do not lend themselves to mass appraisal).',
    education: 'Education: University of Texas – Arlington, BBA – Real Estate',
    photo: brad,
    linkedin: 'https://www.linkedin.com/in/brad-matheidas-7b896112/',
    email: "Brad.Matheidas@slatepts.com"
  };
  const willBeazley = {
    name: 'Will Beazley, MAI',
    role: 'Principal',
    bio: 'William Beazley was born and raised in the property tax business and has over ten (10) years of specialized experience in healthcare (hospitals, senior living, medical office, surgery centers, free-standing emergency departments) and hospitality assets. He holds the MAI designation by the Appraisal Institute and brings a deep knowledge of valuation methodology related to complex assets. \nPrior to Slate, Mr. Beazley was a Senior Director, Offer Management at ARGUS Software and previously, Senior Director of Property Tax at Altus Group US. Additionally, Will was an associate project manager at ADAMS Management Services whose mission is to advocate the interests of healthcare institutions in the planning and management of their capital programs.',
    education: 'Education: Undergraduate - Texas A&M University (Finance and Business Honors)',
    photo: will,
    linkedin: 'https://www.linkedin.com/in/william-beazley-53081894/',
    email: 'Will.Beazley@slatepts.com'
  };
  const trey = {
    name: 'Trey Beazley, MAI',
    role: 'Executive Director',
    bio: 'Trey Beazley is a seasoned executive with over 30 years of experience in the State and Local Tax sector. His career highlights include enhancing client profitability and driving strategic growth through operational excellence. A retired MAI of the Appraisal Institute, Beazley has an in-depth understanding of valuation methodologies for complex properties.\n\nBefore joining Slate, he served as President of Altus Group US Tax from 2013 to 2023, President of Complex Property Advisors Corp from 1997 to 2013, and was the Owner of Beazley & Associates from 1984 to 1997. Under his leadership, clients experienced over $2 billion in savings. Beazley pioneered property tax management services for the healthcare sector, establishing his firms as the market leader. He also built strong relationships within the healthcare industry, represented his companies at various events to increase visibility, and managed daily operations with a focus on client first.\n\nAdditionally, he is an author, contributing to literature on the valuation of hospitals and senior living facilities for property tax purposes. A Texas A&M University alumnus, his personal interests include spending time with his family, fly fishing, and hiking.',
    education: 'Education: Texas A&M University - B.S. Agricultural Economics',
    photo: treyimg,
    linkedin: 'https://www.linkedin.com/in/trey-beazley-mai-459b6517/',
    email: 'Trey.Beazley@slatepts.com'
  };
  const assessment = {
    name: 'Assessment Analysis',
    bio: 'Our team of experienced consultants and appraisers perform an in-depth analysis of each property’s value every year. In this analysis, we review market data, subject performance, intangible and business value components, obsolescence, deferred maintenance and other criteria to complete a detailed analysis of the feasibility of an appeal. We specialize in quantifying and extracting intangible value inherently present in complex commercial assets, such as healthcare facilities and hotels. The analysis performed by our team of experts goes well beyond the scope of “mass-appraisal” techniques employed by assessing jurisdictions and other generalist consulting firms. Our specialized analysis ensures we identify and maximize all potential tax savings opportunities for our clients.',    photo: assessmentImg
  };
  const appeals = {
    name: 'Administrative Appeals',
    bio: 'Our property tax appeal service is designed to manage the appeals process smoothly and effectively. We guide clients from the start, preparing all necessary documentation and developing a strategic plan for each appeal. Throughout the process, we present comprehensive evidence and testimony during both informal negotiations and formal hearings. Our primary objective is to negotiate a fair and equitable property valuation to maximize your potential property tax savings. With our dedicated approach, you can trust that your property tax appeals are handled with the highest level of expertise and care.',
    photo: appealsImg,
    title2: 'Litigation',
    description2: 'If an administrative appeal does not achieve the desired outcome, we seamlessly extend the appeal into the litigation phase. We have long standing relationships with legal counsel nationwide and coordinate our efforts with trusted attorneys who file and maintain litigation in good standing. Importantly, while attorneys bring their expertise in litigation, they are not valuation or consulting specialists. This is where we excel: as property tax experts, we continue to provide strategic consultation and negotiate directly with the opposing party to achieve the most favorable result for you.'
  };
  const due = {
    name: 'Due Diligence Property Tax Estimates',
    bio: 'Our pre-acquisition and development property tax due diligence service equips clients with the critical insights needed to make informed investment decisions. For each development and transaction, we provide budgetary estimates as well as insights into how property taxes are handled in a certain jurisdiction. Our property tax estimates are based on market data, transaction/development specific data, and a review of how jurisdictions have historically treated similar transactions and developments. Our proactive approach ensures that clients have a complete understanding of their potential property tax liabilities before development commences or a purchase is finalized, minimizing the potential of surprises in the future.',    
    photo: dueImg
  };
  const taxbill = {
    name: 'Tax Bill Transmittals',
    bio: 'Our tax bill transmittal service streamlines the management of property tax bills by acquiring and organizing them into a consistent, easy-to-read format. The look and details of tax bills vary significantly across different jurisdictions. Trying to decipher and understand the differences between each unique tax bill takes time and can lead to penalties and interest due to delinquent or inaccurate payments. Our transmittals simplify the process by providing the key information in a standardized format for all property tax bills across your portfolio. By providing clear, accurate and timely transmission of tax bills, we mitigate our clients’ risk of incurring penalties and interest and ensure they are aware and can take full advantage of any available property tax discounts or credits.',
    photo: taxImg
  };
  const budgets = {
    name: 'Budgets and Insights',
    bio: 'Our budgets and insights service offers clients comprehensive support in forecasting and managing their property tax liabilities. We analyze historical tax data, current market trends, and jurisdictional changes to provide detailed projections that inform budgeting decisions. By delivering actionable insights, we empower our clients to make informed financial plans and allocate resources effectively. This service not only aids in anticipating future tax obligations but also enhances overall financial strategy, ensuring clients are well-prepared for any shifts in property tax assessments.',
    photo: budgetsImg
  };
  const personal = {
    name: 'Personal Property Filings',
    bio: 'Our personal property filing service ensures compliance with local tax requirements while maximizing filing accuracy. We handle every aspect of the process, including inventorying tangible assets, preparing detailed reports, and submitting required forms. By leveraging our expertise in complex valuation methodologies, we ensure your filings reflect the true value of your assets while also working to identify opportunities for potential tax savings. With our precise and timely approach, you can streamline your property tax management and focus on maximizing the value of your portfolio.',
    photo: personalImg
  };
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact Component={Home} />
          <Route path='/Charlie-Young-Bio'
            element={<Bio
              name={charlieYoung.name}
              role={charlieYoung.role}
              bio={charlieYoung.bio}
              education={charlieYoung.education}
              photo={charlieYoung.photo}
              linkedin={charlieYoung.linkedin}
              email={charlieYoung.email}
            />}
          />
          <Route path='/Brad-Matheidas-Bio'
            element={<Bio
              name={bradMateidas.name}
              role={bradMateidas.role}
              bio={bradMateidas.bio}
              education={bradMateidas.education}
              photo={bradMateidas.photo}
              linkedin={bradMateidas.linkedin}
              email={bradMateidas.email}
            />}
          />
          <Route path='/Will-Beazley-Bio'
            element={<Bio
              name={willBeazley.name}
              role={willBeazley.role}
              bio={willBeazley.bio}
              education={willBeazley.education}
              photo={willBeazley.photo}
              linkedin={willBeazley.linkedin}
              email={willBeazley.email}
            />}
          />
          <Route path='/Trey-Beazley-Bio'
            element={<Bio
              name={trey.name}
              role={trey.role}
              bio={trey.bio}
              education={trey.education}
              photo={trey.photo}
              linkedin={trey.linkedin}
              email={trey.email}
            />}
          />
          <Route path='/assessment-details'
              element={<ServiceDetails
              title={assessment.name}
              description={assessment.bio}
              image={assessment.photo}
            />}            
          />
          <Route path='/appeals-details'
              element={<ServiceDetails
              title={appeals.name}
              description={appeals.bio}
              image={appeals.photo}
              title2={appeals.title2}
              description2={appeals.description2}
            />}            
          />
          <Route path='/due-diligence-details'
              element={<ServiceDetails
              title={due.name}
              description={due.bio}
              image={due.photo}
            />}            
          />
          <Route path='/tax-bill-details'
              element={<ServiceDetails
              title={taxbill.name}
              description={taxbill.bio}
              image={taxbill.photo}
            />}            
          />
          <Route path='/budgets-details'
              element={<ServiceDetails
              title={budgets.name}
              description={budgets.bio}
              image={budgets.photo}
            />}            
          />
          <Route path='/personal-property-details'
              element={<ServiceDetails
              title={personal.name}
              description={personal.bio}
              image={personal.photo}
            />}            
          />
        </Routes>
        <NewFooter/>
      </Router>
    </>
  );
}

export default App;
